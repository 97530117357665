<template>
  <v-container fluid>
    <div class="d-flex justify-center">
      <v-img
        alt="lecleire logo"
        class="shrink mt-10"
        contain
        src="@/assets/images/logoLeCleire.png"
        width="300"
      />
    </div>
    <div class="text-center mt-8 mb-8">
      <p class="text-h3 font-weight-light">Seleccione un país</p>
    </div>

    <v-row justify="center">
      <v-col cols="3">
        <v-card>
          <div class="d-flex justify-center">
            <img
              alt="logo bandera de guatemala"
              class="shrink mt-6 mb-2"
              contain
              src="@/assets/images/bandera_guatemala.png"
              width="120"
            />
          </div>
          <div class="text-center">
            <p class="text-h4">Guatemala</p>
          </div>
          <div class="d-flex justify-center mt-n6">
            <v-radio-group v-model="chekGuatemala" column>
              <v-radio
                value="guatemala"
                color="primary"
                @click="fnPaisSelect()"
              ></v-radio>
            </v-radio-group>
          </div>
        </v-card>
      </v-col>

      <v-col cols="3">
        <v-card>
          <div class="d-flex justify-center">
            <img
              alt="logo bandera de guatemala"
              class="shrink mt-6 mb-2"
              contain
              src="@/assets/images/bandera_salvador.png"
              width="120"
            />
          </div>
          <div class="text-center">
            <p class="text-h4">El Salvador</p>
          </div>
          <div class="d-flex justify-center mt-n6">
            <v-radio-group v-model="chekSalvador" column>
              <v-radio
                value="salvador"
                color="primary"
                @click="fnPaisSelect()"
              ></v-radio>
            </v-radio-group>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'selectPais',
  data() {
    return {
      chekGuatemala: false,
      chekSalvador: false,
    };
  },
  methods: {
    fnPaisSelect() {
      console.log('se seleccionó: ', this.chekGuatemala);
      console.log('se seleccionó: ', this.chekSalvador);
    },
  },
};
</script>

<style></style>
